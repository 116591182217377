@font-face {
  font-family: 'EnzoBold';
  src: url('https://cdn.webhr.co/fonts/EnzoBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AzoSansBlack';
  src: url('/public/fonts/AzoSansBlack.woff') format('woff');
  font-style: normal; /* Optional: specify font-style */
  font-display: swap;
}
@font-face {
  font-family: 'AzoSansBold';
  src: url('/public/fonts/AzoSansBold.woff') format('woff');
  font-style: normal; /* Optional: specify font-style */
  font-display: swap;
  font-weight: 900;
}
@font-face {
  font-family: 'AzoSansMedium';
  src: url('/public/fonts/AzoSansMedium.woff') format('woff');
  font-style: normal; /* Optional: specify font-style */
  font-display: swap;
}
@font-face {
  font-family: 'AzoSansRegular';
  src: url('/public/fonts/AzoSansRegular.woff') format('woff');
  font-style: normal; /* Optional: specify font-style */
  font-display: swap;
}

@font-face {
  font-family: 'EnzoLight';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('https://cdn.webhr.co/fonts/EnzoLight.woff') format('woff');
}
@font-face {
  font-family: 'AzoItalic';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('/public/fonts/AzoSansItalic.woff') format('woff');
}
@font-face {
  font-family: 'AzoSans';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('/public/fonts/AzoSansRegular.woff') format('woff');
}
@font-face {
  font-family: 'AzoLight';
  src: url('/public/fonts/AzoSansLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'AzoSansRegular', sans-serif;
  letter-spacing: -0.25px;
  --bs-accordion-bg: #f8fafc !important;
}
body {
  overflow-x: hidden;
  background: #fff !important;
  font-family: 'AzoSans', sans-serif;
  font-display: swap;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.container {
  max-width: 1300px !important;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #fff !important;
}
.carousel.carousel-slider {
  min-height: 250px;
}

.carousel .control-dots .dot,
.dot.selected {
  background: #b1b1b1 !important;
  box-shadow: none !important;
  opacity: 0.3;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #b1b1b1 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #b1b1b1 !important;
}

.carousel .slider.animated {
  align-items: center !important;
}

/*Additional*/
.h2 {
  font-size: 1.25rem;
}
.section-heading-container {
  display: flex;
}

.Toastify__toast--default {
  background-color: #0c64ae !important;
  border-radius: 6px !important;
  padding: 0.5em 1.25em !important;
  color: #fff !important;
  box-shadow: none !important;
  padding-top: 10px !important;
}

label {
  display: flex;
  margin-bottom: 0.5rem;
}

iframe[name='__tcfapiLocator'] {
  height: 0 !important;
  visibility: hidden !important;
  display: none !important;
}

.custom-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: white !important; /* Thumb color */
  border: 2px solid #0c64ae !important;
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
  margin-top: -5px !important;
}

.custom-range-slider::-moz-range-thumb {
  background-color: red; /* Thumb color */
  border-radius: 50%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.custom-accordion .accordion-button:not(.collapsed) {
  color: #212529 !important; /* Collapsed text color */
}
.custom-accordion .accordion-button:focus {
  box-shadow: none !important; /* Remove the blue focus outline */
}
.custom-accordion .accordion-button.collapsed {
  font-weight: bold;
  color: #212529 !important; /* Collapsed text color */
}
.custom-accordion .accordion-button:not(.collapsed)::after {
  color: #212529 !important; /* Collapsed text color */
  background-image: black !important;
}

.custom-accordion .accordion-button {
  background-color: #f8f9fa !important; /* Collapsed background color */
  color: #212529 !important; /* Collapsed text color */
  font-weight: bold;
}

#tawk-messaging-container {
  z-index: 1000; /* Adjust this value to a lower priority than your modal */
}

.modal {
  z-index: 1050; /* Ensure this is higher than Tawk's widget */
}
.Toastify__toast--default {
  background-color: #f8f9fa;
}
@media (max-width: 768px) {
  .custom-range-slider {
    width: 90%; /* Adjust the width for smaller devices */
  }
}
